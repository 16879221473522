
// import React from 'react';
// import { gql } from '@apollo/client';
// import { useQuery } from '@apollo/client';
// import { useParams, Link } from 'react-router-dom';
// import { useGlobalState } from '../globalState';
// import Confetti from 'react-confetti';
// import { AnimatePresence, motion } from 'framer-motion'; // Ensure these are imported

// const GET_PRODUCTS = gql`
//   query GetProducts($category: String) {
//     products(category: $category) {
//       id
//       name
//       description
//       inStock
//       price {
//         amount
//         currency_label
//         currency_symbol
//       }
//       category {
//         id
//         name
//       }
//       attributes {
//         id
//         name
//         type
//         items {
//           id
//           displayValue
//           value
//         }
//       }
//       gallery {
//         image_url
//       }
//     }
//   }
// `;

// const Products = ({ addToCart, category }) => {
//   const { categoryName } = useParams();
//   const effectiveCategory = category || categoryName;
//   const { loading, error, data } = useQuery(GET_PRODUCTS, {
//     variables: { category: effectiveCategory === 'all' ? null : effectiveCategory },
//   });
//   const [showSuccess] = useGlobalState("showSuccess");

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error :(</p>;

//   const handleQuickShop = (product) => {
//     const defaultAttributes = product.attributes.reduce((acc, attr) => {
//       acc[attr.name] = attr.items[0].value;
//       return acc;
//     }, {});

//     const formattedAttributes = product.attributes.map(attr => ({
//       ...attr,
//       items: attr.items.map(item => ({
//         ...item,
//         selected: item.value === defaultAttributes[attr.name]
//       }))
//     }));

//     const productWithDefaults = {
//       ...product,
//       attributes: formattedAttributes,
//       price: product.price ? product.price[0] : null,
//       image: product.gallery ? product.gallery[0]?.image_url : ''
//     };

//     addToCart(productWithDefaults);
//   };

//   return (
//     <div className="products-container">
//       <h1 className="text-4xl font-normal my-5 capitalize ml-7">{effectiveCategory}</h1>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//         {data.products.map((product) => (
//           <Link
//             to={`/product/${product.id}`}
//             key={product.id}
//             className="product-card relative bg-white rounded-lg transition duration-300 transform group"
//             data-testid={`product-${product.name.toLowerCase().replace(/\s+/g, '-')}`}
//           >
//             <div className="square-img-container relative">
//               <img
//                 src={product.gallery[0]?.image_url || ''}
//                 alt={product.name}
//                 className="square-img"
//               />
//               {!product.inStock && (
//                 <div className="absolute top-0 left-0 w-full h-full bg-white opacity-50 flex items-center justify-center">
//                   <span className="text-gray-600 font-semibold text-xl">OUT OF STOCK</span>
//                 </div>
//               )}
//             </div>
//             {product.inStock && (
//               <button
//                 className="quick-shop-btn absolute bg-[#56e381] text-white py-3 px-3 transition-opacity duration-300"
//                 onClick={(e) => {
//                   e.preventDefault();
//                   handleQuickShop(product);
//                 }}
//                 data-testid={`quick-shop-btn-${product.name.toLowerCase().replace(/\s+/g, '-')}`}
//               >
//                 <i className="fas fa-cart-plus"></i>
//               </button>
//             )}
//             <h2 className="product-text-size font-normal mt-2 text-gray-600">{product.name}</h2>
//             <p className="price-text-size font-bold">
//               {product.price[0].currency_symbol}
//               {product.price[0].amount.toFixed(2)}
//             </p>
//           </Link>
//         ))}
//       </div>
//       <AnimatePresence>
//         {showSuccess && (
//           <motion.div
//             initial={{ opacity: 0, scale: 0.8 }}
//             animate={{ opacity: 1, scale: 1 }}
//             exit={{ opacity: 0, scale: 0.8 }}
//             transition={{ duration: 0.3 }}
//             className="success-overlay"
//           >
//             <div className="success-content">
//               <Confetti />
//               <p>Your order has been successfully placed!</p>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// export default Products;












import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import { useGlobalState } from '../globalState';
import Confetti from 'react-confetti';
import { AnimatePresence, motion } from 'framer-motion'; // Ensure these are imported
import { Helmet } from 'react-helmet';

const GET_PRODUCTS = gql`
  query GetProducts($category: String) {
    products(category: $category) {
      id
      name
      description
      inStock
      price {
        amount
        currency_label
        currency_symbol
      }
      category {
        id
        name
      }
      attributes {
        id
        name
        type
        items {
          id
          displayValue
          value
        }
      }
      gallery {
        image_url
      }
    }
  }
`;

const Products = ({ addToCart, category }) => {
  const { categoryName } = useParams();
  const effectiveCategory = category || categoryName;
  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    variables: { category: effectiveCategory === 'all' ? null : effectiveCategory },
  });
  const [showSuccess] = useGlobalState("showSuccess");

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
          .then(registration => {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
          })
          .catch(error => {
            console.log('ServiceWorker registration failed: ', error);
          });
      });
    }
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleQuickShop = (product) => {
    const defaultAttributes = product.attributes.reduce((acc, attr) => {
      acc[attr.name] = attr.items[0].value;
      return acc;
    }, {});

    const formattedAttributes = product.attributes.map(attr => ({
      ...attr,
      items: attr.items.map(item => ({
        ...item,
        selected: item.value === defaultAttributes[attr.name]
      }))
    }));

    const productWithDefaults = {
      ...product,
      attributes: formattedAttributes,
      price: product.price ? product.price[0] : null,
      image: product.gallery ? product.gallery[0]?.image_url : ''
    };

    addToCart(productWithDefaults);
  };

  return (
    <div className="products-container">
      <Helmet>
        <link rel="manifest" href="/manifest.json" />
      </Helmet>
      <h1 className="text-4xl font-normal my-5 capitalize ml-7">{effectiveCategory}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {data.products.map((product) => (
          <Link
            to={`/product/${product.id}`}
            key={product.id}
            className="product-card relative bg-white rounded-lg transition duration-300 transform group"
            data-testid={`product-${product.name.toLowerCase().replace(/\s+/g, '-')}`}
          >
            <div className="square-img-container relative">
              <img
                src={product.gallery[0]?.image_url || ''}
                alt={product.name}
                className="square-img"
              />
              {!product.inStock && (
                <div className="absolute top-0 left-0 w-full h-full bg-white opacity-50 flex items-center justify-center">
                  <span className="text-gray-600 font-semibold text-xl">OUT OF STOCK</span>
                </div>
              )}
            </div>
            {product.inStock && (
              <button
                className="quick-shop-btn absolute bg-[#56e381] text-white py-3 px-3 transition-opacity duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  handleQuickShop(product);
                }}
                data-testid={`quick-shop-btn-${product.name.toLowerCase().replace(/\s+/g, '-')}`}
              >
                <i className="fas fa-cart-plus"></i>
              </button>
            )}
            <h2 className="product-text-size font-normal mt-2 text-gray-600">{product.name}</h2>
            <p className="price-text-size font-bold">
              {product.price[0].currency_symbol}
              {product.price[0].amount.toFixed(2)}
            </p>
          </Link>
        ))}
      </div>
      <AnimatePresence>
        {showSuccess && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            className="success-overlay"
          >
            <div className="success-content">
              <Confetti />
              <p>Your order has been successfully placed!</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Products;
