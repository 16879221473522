import React, { createContext, useContext, useState } from "react";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <GlobalStateContext.Provider value={{ showSuccess, setShowSuccess }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = (key) => {
  const context = useContext(GlobalStateContext);

  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }

  return [context[key], context[`set${key.charAt(0).toUpperCase() + key.slice(1)}`]];
};
