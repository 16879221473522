



// import React, { useState, useEffect } from 'react';
// import { Routes, Route } from 'react-router-dom';
// import Header from './components/Header';
// import Products from './components/Products';
// import ProductDetails from './components/ProductDetails';
// import CartOverlay from './components/CartOverlay';

// const App = () => {
//   const [cartItems, setCartItems] = useState([]);
//   const [isCartOpen, setIsCartOpen] = useState(false);

//   const toggleCartOverlay = () => {
//     if (cartItems.length > 0) {
//       setIsCartOpen(!isCartOpen);
//       document.body.classList.toggle('cart-overlay-open');
//     }
//   };

//   const addToCart = (product) => {
//     const productWithAttributesArray = {
//       ...product,
//       attributes: product.attributes.map(attr => ({
//         ...attr,
//         items: attr.items.map(item => ({
//           ...item,
//           selected: item.selected
//         }))
//       })),
//       price: product.price ? product.price : null,
//       image: product.image ? product.image : ''
//     };

//     const existingProduct = cartItems.find(
//       (item) =>
//         item.id === productWithAttributesArray.id &&
//         JSON.stringify(item.attributes) === JSON.stringify(productWithAttributesArray.attributes)
//     );

//     if (existingProduct) {
//       setCartItems(
//         cartItems.map((item) =>
//           item.id === productWithAttributesArray.id &&
//           JSON.stringify(item.attributes) === JSON.stringify(productWithAttributesArray.attributes)
//             ? { ...item, quantity: item.quantity + 1 }
//             : item
//         )
//       );
//     } else {
//       setCartItems([...cartItems, { ...productWithAttributesArray, quantity: 1 }]);
//     }
//   };

//   const clearCart = () => {
//     setCartItems([]);
//     localStorage.removeItem('cartItems');
//     setIsCartOpen(false);
//     document.body.classList.remove('cart-overlay-open');
//   };

//   useEffect(() => {
//     const savedCartItems = localStorage.getItem('cartItems');
//     if (savedCartItems) {
//       setCartItems(JSON.parse(savedCartItems));
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem('cartItems', JSON.stringify(cartItems));
//     if (cartItems.length === 0) {
//       setIsCartOpen(false);
//       document.body.classList.remove('cart-overlay-open');
//     }
//   }, [cartItems]);

//   return (
//     <div>
//       <Header cartCount={cartItems.length} toggleCartOverlay={toggleCartOverlay} />
//       <Routes>
//         <Route path="/" element={<Products addToCart={addToCart} />} />
//         <Route path="/category/:categoryName" element={<Products addToCart={addToCart} />} />
//         <Route path="/tech" element={<Products addToCart={addToCart} category="tech" />} />
//         <Route path="/all" element={<Products addToCart={addToCart} category="all" />} />
//         <Route path="/clothes" element={<Products addToCart={addToCart} category="clothes" />} />
//         <Route path="/product/:id" element={<ProductDetails addToCart={addToCart} />} />
//       </Routes>
//       {isCartOpen && (
//         <CartOverlay 
//           cartItems={cartItems} 
//           setCartItems={setCartItems} 
//           clearCart={clearCart} 
//           isOpen={isCartOpen} 
//           toggleCartOverlay={toggleCartOverlay} 
//         />
//       )}
//     </div>
//   );
// };

// export default App;








import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Products from './components/Products';
import ProductDetails from './components/ProductDetails';
import CartOverlay from './components/CartOverlay';
import { GlobalStateProvider } from './globalState'; // Import GlobalStateProvider

const App = () => {
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCartOverlay = () => {
    if (isCartOpen) {
      handleClose();
    } else if (cartItems.length > 0) {
      setIsCartOpen(true);
      document.body.classList.add('cart-overlay-open');
    }
  };

  const handleClose = () => {
    console.log('handleClose called');
    setIsCartOpen(false);
    document.body.classList.remove('cart-overlay-open');
  };

  const addToCart = (product) => {
    const productWithAttributesArray = {
      ...product,
      attributes: product.attributes.map(attr => ({
        ...attr,
        items: attr.items.map(item => ({
          ...item,
          selected: item.selected
        }))
      })),
      price: product.price ? product.price : null,
      image: product.image ? product.image : ''
    };

    const existingProduct = cartItems.find(
      (item) =>
        item.id === productWithAttributesArray.id &&
        JSON.stringify(item.attributes) === JSON.stringify(productWithAttributesArray.attributes)
    );

    if (existingProduct) {
      setCartItems(
        cartItems.map((item) =>
          item.id === productWithAttributesArray.id &&
          JSON.stringify(item.attributes) === JSON.stringify(productWithAttributesArray.attributes)
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    } else {
      setCartItems([...cartItems, { ...productWithAttributesArray, quantity: 1 }]);
    }
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
    handleClose();
  };

  useEffect(() => {
    const savedCartItems = localStorage.getItem('cartItems');
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    if (cartItems.length === 0) {
      handleClose();
    }
  }, [cartItems]);

  return (
    <GlobalStateProvider> {/* Wrap the app with GlobalStateProvider */}
      <div>
        <Header cartCount={cartItems.length} toggleCartOverlay={toggleCartOverlay} />
        <Routes>
          <Route path="/" element={<Products addToCart={addToCart} />} />
          <Route path="/category/:categoryName" element={<Products addToCart={addToCart} />} />
          <Route path="/tech" element={<Products addToCart={addToCart} category="tech" />} />
          <Route path="/all" element={<Products addToCart={addToCart} category="all" />} />
          <Route path="/clothes" element={<Products addToCart={addToCart} category="clothes" />} />
          <Route path="/product/:id" element={<ProductDetails addToCart={addToCart} />} />
        </Routes>
        {isCartOpen && (
          <CartOverlay 
            cartItems={cartItems} 
            setCartItems={setCartItems} 
            clearCart={clearCart} 
            isOpen={isCartOpen} 
            toggleCartOverlay={toggleCartOverlay} 
          />
        )}
      </div>
    </GlobalStateProvider>
  );
};

export default App;
