

// import React, { useState, useEffect } from 'react';
// import { gql, useQuery } from '@apollo/client';
// import { useParams } from 'react-router-dom';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import DOMPurify from 'dompurify';
// import parse from 'html-react-parser';
// import '../index.css';

// const GET_PRODUCT = gql`
//   query GetProduct($id: Int!) {
//     product(id: $id) {
//       id
//       name
//       description
//       inStock
//       price {
//         amount
//         currency_label
//         currency_symbol
//       }
//       attributes {
//         id
//         name
//         type
//         items {
//           id
//           displayValue
//           value
//         }
//       }
//       gallery {
//         image_url
//       }
//     }
//   }
// `;

// const ProductDetails = ({ addToCart }) => {
//   const { id } = useParams();
//   const { loading, error, data } = useQuery(GET_PRODUCT, {
//     variables: { id: parseInt(id) },
//   });
//   const [selectedAttributes, setSelectedAttributes] = useState({});
//   const [selectedImageIndex, setSelectedImageIndex] = useState(0);

//   useEffect(() => {
//     const savedAttributes = sessionStorage.getItem(`selectedAttributes-${id}`);
//     if (savedAttributes) {
//       setSelectedAttributes(JSON.parse(savedAttributes));
//     }
//   }, [id]);

//   useEffect(() => {
//     const handleBeforeUnload = () => {
//       sessionStorage.removeItem(`selectedAttributes-${id}`);
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [id]);

//   useEffect(() => {
//     sessionStorage.setItem(`selectedAttributes-${id}`, JSON.stringify(selectedAttributes));
//   }, [selectedAttributes, id]);

//   useEffect(() => {
//     return () => {
//       sessionStorage.removeItem(`selectedAttributes-${id}`);
//       setSelectedAttributes({});
//     };
//   }, [id]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error :(</p>;

//   const product = data.product;

//   const toKebabCase = (str) =>
//     str && str.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/[\s_]+/g, '-').toLowerCase();

//   const handleAttributeChange = (attrName, itemValue) => {
//     setSelectedAttributes((prev) => ({
//       ...prev,
//       [attrName]: itemValue,
//     }));
//   };

//   const handleAddToCart = () => {
//     const item = {
//       id: product.id,
//       name: product.name,
//       image: product.gallery[0]?.image_url,
//       price: product.price[0],
//       attributes: product.attributes.map((attr) => ({
//         id: attr.id,
//         name: attr.name,
//         type: attr.type,
//         items: attr.items.map((item) => ({
//           ...item,
//           selected: selectedAttributes[attr.name] === item.value
//         }))
//       }))
//     };
//     addToCart(item);
//     setSelectedAttributes({});
//   };

//   const renderDescription = (description) => {
//     const cleanHTML = DOMPurify.sanitize(description);
//     return parse(cleanHTML);
//   };

//   return (
//   <div className='lg:mr-[270px]'>
//     <div className="product-details-container flex flex-col md:flex-col lg:flex-row">
//       <div className="gallery-container flex flex-col md:flex-col lg:flex-row mb-4 lg:mr-4 lg:justify-end" data-testid="product-gallery">
//         <div className="gallery flex lg:flex-col mb-4 md:mr-4">
//           {product.gallery.map((img, index) => (
//             <img
//               key={index}
//               src={img.image_url}
//               alt={product.name}
//               className="gallery-image"
//               onClick={() => setSelectedImageIndex(index)}
//             />
//           ))}
//         </div>
//         <Carousel
//           showArrows={product.gallery.length > 1}
//           showThumbs={false}
//           selectedItem={selectedImageIndex}
//           onChange={(index) => setSelectedImageIndex(index)}
//           className="w-full md:w-full lg:w-1/2"
//           infiniteLoop={product.gallery.length > 1}
//           renderArrowPrev={(onClickHandler, hasPrev, label) =>
//             hasPrev && (
//               <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-left">
//                 <i className="fas fa-chevron-left"></i>
//               </button>
//             )
//           }
//           renderArrowNext={(onClickHandler, hasNext, label) =>
//             hasNext && (
//               <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-right">
//                 <i className="fas fa-chevron-right"></i>
//               </button>
//             )
//           }
//         >
//           {product.gallery.map((img, index) => (
//             <div key={index}>
//               <img src={img.image_url} alt={product.name} className="w-full object-cover" />
//             </div>
//           ))}
//         </Carousel>
//       </div>
//       <div className="product-info mt-4 md:mt-4 lg:mt-0">
//         <h1 className="text-3xl font-normal mb-4">{product.name}</h1>
//         <div className="product-attributes mb-4">
//           {product.attributes.map((attr) => (
//             <div key={attr.id} data-testid={`product-attribute-${toKebabCase(attr.name)}`}>
//               <h3 className="pt-5 text-lg font-semibold uppercase">{attr.name}:</h3>
//               <div className="flex space-x-2">
//                 {attr.items.map((item) => (
//                   <button
//                     key={item.id}
//                     className={`attribute-item att-box p-2 border-2 ${selectedAttributes[attr.name] === item.value ? 'selected' : ''} ${
//                       attr.type === 'swatch' ? 'color-swatch' : ''
//                     }`}
//                     style={{ backgroundColor: attr.type === 'swatch' ? item.value : '' }}
//                     onClick={() => handleAttributeChange(attr.name, item.value)}
//                     disabled={!product.inStock}
//                     data-testid={`product-attribute-${toKebabCase(attr.name)}-${toKebabCase(item.displayValue)}${selectedAttributes[attr.name] === item.value ? '-selected' : ''}`}
//                   >
//                     {attr.type !== 'swatch' && item.value}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           ))}
//         </div>
//         <span className="text-lg font-semibold uppercase">Price:</span>
//         <p className="text-xl font-bold mb-4">
//           {product.price[0].currency_symbol}
//           {product.price[0].amount.toFixed(2)}
//         </p>
//         <button
//           className="add-to-cart-btn bg-[#56e381] text-white p-3 disabled:bg-gray-400"
//           data-testid="add-to-cart"
//           onClick={handleAddToCart}
//           disabled={!product.inStock || Object.keys(selectedAttributes).length !== product.attributes.length}
//         >
//           ADD TO CART
//         </button>
//         <div className="product-description mt-4" data-testid="product-description">
//           {renderDescription(product.description)}
//         </div>
//       </div>
//     </div>
//   </div>
//   );
// };

// export default ProductDetails;




// PRIOR TO CONFETTI CODE ABOVE








import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import Confetti from 'react-confetti';
import { AnimatePresence, motion } from 'framer-motion';
import { useGlobalState } from '../globalState';
import '../index.css';

const GET_PRODUCT = gql`
  query GetProduct($id: Int!) {
    product(id: $id) {
      id
      name
      description
      inStock
      price {
        amount
        currency_label
        currency_symbol
      }
      attributes {
        id
        name
        type
        items {
          id
          displayValue
          value
        }
      }
      gallery {
        image_url
      }
    }
  }
`;

const ProductDetails = ({ addToCart }) => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { id: parseInt(id) },
  });
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showSuccess] = useGlobalState("showSuccess");

  useEffect(() => {
    const savedAttributes = sessionStorage.getItem(`selectedAttributes-${id}`);
    if (savedAttributes) {
      setSelectedAttributes(JSON.parse(savedAttributes));
    }
  }, [id]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem(`selectedAttributes-${id}`);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [id]);

  useEffect(() => {
    sessionStorage.setItem(`selectedAttributes-${id}`, JSON.stringify(selectedAttributes));
  }, [selectedAttributes, id]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(`selectedAttributes-${id}`);
      setSelectedAttributes({});
    };
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const product = data.product;

  const toKebabCase = (str) =>
    str && str.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/[\s_]+/g, '-').toLowerCase();

  const handleAttributeChange = (attrName, itemValue) => {
    setSelectedAttributes((prev) => ({
      ...prev,
      [attrName]: itemValue,
    }));
  };

  const handleAddToCart = () => {
    const item = {
      id: product.id,
      name: product.name,
      image: product.gallery[0]?.image_url,
      price: product.price[0],
      attributes: product.attributes.map((attr) => ({
        id: attr.id,
        name: attr.name,
        type: attr.type,
        items: attr.items.map((item) => ({
          ...item,
          selected: selectedAttributes[attr.name] === item.value
        }))
      }))
    };
    addToCart(item);
    setSelectedAttributes({});
  };

  const renderDescription = (description) => {
    const cleanHTML = DOMPurify.sanitize(description);
    return parse(cleanHTML);
  };

  return (
    <div className='lg:mr-[270px]'>
      <div className="product-details-container flex flex-col md:flex-col lg:flex-row">
        <div className="gallery-container flex flex-col md:flex-col lg:flex-row mb-4 lg:mr-4 lg:justify-end" data-testid="product-gallery">
          <div className="gallery flex lg:flex-col mb-4 md:mr-4">
            {product.gallery.map((img, index) => (
              <img
                key={index}
                src={img.image_url}
                alt={product.name}
                className="gallery-image"
                onClick={() => setSelectedImageIndex(index)}
              />
            ))}
          </div>
          <Carousel
            showArrows={product.gallery.length > 1}
            showThumbs={false}
            selectedItem={selectedImageIndex}
            onChange={(index) => setSelectedImageIndex(index)}
            className="w-full md:w-full lg:w-1/2"
            infiniteLoop={product.gallery.length > 1}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-left">
                  <i className="fas fa-chevron-left"></i>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-right">
                  <i className="fas fa-chevron-right"></i>
                </button>
              )
            }
          >
            {product.gallery.map((img, index) => (
              <div key={index}>
                <img src={img.image_url} alt={product.name} className="w-full object-cover" />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="product-info mt-4 md:mt-4 lg:mt-0">
          <h1 className="text-3xl font-normal mb-4">{product.name}</h1>
          <div className="product-attributes mb-4">
            {product.attributes.map((attr) => (
              <div key={attr.id} data-testid={`product-attribute-${toKebabCase(attr.name)}`}>
                <h3 className="pt-5 text-lg font-semibold uppercase">{attr.name}:</h3>
                <div className="flex space-x-2">
                  {attr.items.map((item) => (
                    <button
                      key={item.id}
                      className={`attribute-item att-box p-2 border-2 ${selectedAttributes[attr.name] === item.value ? 'selected' : ''} ${
                        attr.type === 'swatch' ? 'color-swatch' : ''
                      }`}
                      style={{ backgroundColor: attr.type === 'swatch' ? item.value : '' }}
                      onClick={() => handleAttributeChange(attr.name, item.value)}
                      disabled={!product.inStock}
                      data-testid={`product-attribute-${toKebabCase(attr.name)}-${toKebabCase(item.displayValue)}${selectedAttributes[attr.name] === item.value ? '-selected' : ''}`}
                    >
                      {attr.type !== 'swatch' && item.value}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <span className="text-lg font-semibold uppercase">Price:</span>
          <p className="text-xl font-bold mb-4">
            {product.price[0].currency_symbol}
            {product.price[0].amount.toFixed(2)}
          </p>
          <button
            className="add-to-cart-btn bg-[#56e381] text-white p-3 disabled:bg-gray-400"
            data-testid="add-to-cart"
            onClick={handleAddToCart}
            disabled={!product.inStock || Object.keys(selectedAttributes).length !== product.attributes.length}
          >
            ADD TO CART
          </button>
          <div className="product-description mt-4" data-testid="product-description">
            {renderDescription(product.description)}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showSuccess && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            className="success-overlay"
          >
            <div className="success-content">
              <Confetti />
              <p>Your order has been successfully placed!</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProductDetails;
