




import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { motion, AnimatePresence } from "framer-motion";
import { useGlobalState } from "../globalState"; // Import global state

const PLACE_ORDER = gql`
  mutation PlaceOrder($items: [OrderItemInput!]!) {
    placeOrder(items: $items) {
      id
      status
    }
  }
`;

const CartOverlay = ({
  cartItems,
  setCartItems,
  clearCart,
  isOpen,
  toggleCartOverlay,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useGlobalState("showSuccess"); // Use global state for success message

  const [placeOrder] = useMutation(PLACE_ORDER, {
    onCompleted: () => {
      console.log("Order placed successfully");
      clearCart();
      setShowConfirmation(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    },
    onError: (error) => {
      console.error("Error placing order:", error);
      alert("Failed to place order. Please try again.");
    },
  });

  const handleIncrease = (index) => {
    const updatedCart = [...cartItems];
    updatedCart[index].quantity += 1;
    setCartItems(updatedCart);
  };

  const handleDecrease = (index) => {
    const updatedCart = [...cartItems];
    if (updatedCart[index].quantity === 1) {
      updatedCart.splice(index, 1);
    } else {
      updatedCart[index].quantity -= 1;
    }
    setCartItems(updatedCart);
    if (updatedCart.length === 0) {
      handleClose();
    }
  };

  const handlePlaceOrder = () => {
    console.log("Placing order");
    const items = cartItems.map((item) => ({
      productId: item.id,
      quantity: item.quantity,
      price: item.price.amount,
      attributes: item.attributes.map((attr) => ({
        name: attr.name,
        value:
          attr.name === "Color"
            ? attr.items.find((attrItem) => attrItem.selected).displayValue
            : attr.items.find((attrItem) => attrItem.selected).value,
      })),
    }));

    placeOrder({ variables: { items } });
  };

  const totalAmount = cartItems.reduce(
    (acc, item) => acc + (item.price ? item.price.amount * item.quantity : 0),
    0
  );

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  const toKebabCase = (str) =>
    str &&
    str
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/[\s_]+/g, "-")
      .toLowerCase();

  const handleClose = () => {
    console.log("handleClose called");
    toggleCartOverlay();
    document.body.classList.remove("cart-overlay-open");
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("cart-overlay-open");
    } else {
      document.body.classList.remove("cart-overlay-open");
    }
  }, [isOpen]);

  useEffect(() => {
    console.log("showSuccess changed:", showSuccess);
  }, [showSuccess]);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="cart-overlay-backdrop"
              onClick={handleClose}
            />
            <motion.div
              initial={{ opacity: 0, x: "100%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "100%" }}
              transition={{ duration: 0.3 }}
              className="cart-overlay"
              onClick={handleOverlayClick}
              data-testid="cart-overlay"
            >
              <div className="cart-overlay-content">
                <h2 className="pb-3 mb-3">
                  <span className="font-bold">My Bag</span>, {cartItems.length}{" "}
                  {cartItems.length === 1 ? "Item" : "Items"}
                </h2>
                <ul className="cart-items-list">
                  {cartItems.map((item, index) => (
                    <li
                      key={index}
                      className="cart-item"
                      data-testid={`cart-item-attribute-${toKebabCase(
                        item.name
                      )}`}
                    >
                      <div className="cart-item-details">
                        <h3 className="pb-2">{item.name}</h3>
                        {item.price &&
                        item.price.amount !== undefined &&
                        item.price.currency_symbol !== undefined ? (
                          <p className="pb-2 font-semibold">
                            {item.price.currency_symbol}
                            {item.price.amount.toFixed(2)}
                          </p>
                        ) : (
                          <p className="pb-2">Price not available</p>
                        )}
                        {item.attributes.map((attr, attrIndex) => (
                          <div
                            key={attrIndex}
                            className="cart-item-attribute"
                            data-testid={`cart-item-attribute-${toKebabCase(
                              attr.name
                            )}`}
                          >
                            <span className="mb-2">{attr.name}:</span>
                            <div className="flex space-x-2 mt-1">
                              {attr.items.map((attributeItem, itemIndex) => (
                                <button
                                  key={itemIndex}
                                  className={`p-2 border-2 ${
                                    attributeItem.selected
                                      ? attr.name === "Color"
                                        ? "border-[#56e381] bg-[#56e381] text-white"
                                        : "border-black bg-black text-white"
                                      : attr.name === "Color"
                                      ? "border-gray-300"
                                      : "border-black"
                                  }`}
                                  style={{
                                    backgroundColor:
                                      attr.type === "swatch"
                                        ? attributeItem.value
                                        : "",
                                    ...(attr.type !== "swatch" && {
                                      width: "40px",
                                      height: "35px",
                                      fontSize: "9px",
                                    }),
                                  }}
                                  data-testid={`cart-item-attribute-${toKebabCase(
                                    attr.name
                                  )}-${toKebabCase(
                                    attributeItem.displayValue
                                  )}${
                                    attributeItem.selected ? "-selected" : ""
                                  }`}
                                >
                                  {attr.type !== "swatch" &&
                                    attributeItem.value}
                                </button>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex justify-around self-center h-full">
                        <div className="cart-item-quantity flex flex-col justify-around items-center h-full">
                          <button
                            onClick={() => handleIncrease(index)}
                            data-testid="cart-item-amount-increase"
                            className="w-8 h-8 flex items-center justify-center bg-green-500 text-white"
                          >
                            +
                          </button>
                          <span data-testid="cart-item-amount">
                            {item.quantity}
                          </span>
                          <button
                            onClick={() => handleDecrease(index)}
                            data-testid="cart-item-amount-decrease"
                            className="w-8 h-8 flex items-center justify-center bg-green-500 text-white"
                          >
                            -
                          </button>
                        </div>
                        <div className="cart-item-image-container flex items-center h-full">
                          {item.image && (
                            <img
                              src={item.image}
                              alt={item.name}
                              className="cart-item-image w-40 h-40"
                            />
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="cart-footer">
                  <div
                    className="cart-total flex justify-between"
                    data-testid="cart-total"
                  >
                    <span>Total:</span> <span>${totalAmount.toFixed(2)}</span>
                  </div>
                  <button
                    onClick={() => setShowConfirmation(true)}
                    className="place-order-btn"
                    disabled={cartItems.length === 0}
                    data-testid="place-order-btn"
                  >
                    PLACE ORDER
                  </button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showConfirmation && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            className="confirmation-overlay"
          >
            <div className="confirmation-content">
              <p>Are you sure you want to place the order?</p>
              <button onClick={handlePlaceOrder}>Yes</button>
              <button onClick={() => setShowConfirmation(false)}>No</button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CartOverlay;
