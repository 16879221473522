// import { ApolloClient, InMemoryCache } from '@apollo/client';

// const client = new ApolloClient({
//   uri: 'http://localhost/scandi/graphql_api.php',
//   cache: new InMemoryCache(),
// });

// export default client;


import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  uri: 'https://rulershop.free.nf/backend/graphql_api.php',
  cache: new InMemoryCache(),
});

export default client;




// import { ApolloClient, InMemoryCache } from '@apollo/client';

// const client = new ApolloClient({
//   uri: 'https://rulershop.free.nf/backend/db_test.php',
//   cache: new InMemoryCache(),
// });

// export default client;

